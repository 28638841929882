<template>
  <section class="dtc-main-section">
    <section class="search-block" >
      <h5 class="big-title">
        三年內錄取名單查詢
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="身分證字號">
          <b-form-input
            v-model="searchID"
            placeholder="輸入身分證字號"
          ></b-form-input>
        </b-input-group>

        <b-input-group prepend="姓名">
          <b-form-input
            v-model="searchName"
            placeholder="輸入姓名"
          ></b-form-input>
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="success" @click="$refs.file.click()">匯入資料</b-button>
        <input  ref="file" type="file" accept=".xlsx, .xls" @change="fileChange" hidden />
      </div>
    </section>

    <header
      class="dtc-grid-header my-dark"
      style="text-align: center; justify-content: center;"
    >
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header content"
      v-for="(item, i) in items"
      :key="`content${i}`"
      style="color:#39312E; text-align: center; justify-content: center;"
    >
      <div style="padding:2px 0" >
        <b-button variant="danger" size="sm" @click="showDeleteModal"
          >刪除</b-button
        >
        <b-modal
          id="delete-modal"
          v-model="showModal"
          title="確認刪除"
          @ok="deleteItem"
        >
          <p>您確定要刪除這個項目嗎？此操作無法恢復。</p>

          <!-- 自定義按鈕順序 -->
          <template #modal-footer>
            <b-button @click="deleteItem(item.Identifier)" variant="danger">確定</b-button>
            <b-button @click="showModal = false" variant="secondary">取消</b-button>
          </template>
        </b-modal>
      </div>
      <div :title="item.Identifier">{{ item.Identifier }}</div>
      <div :title="item.Name">{{ item.Name }}</div>
      <div :title="item.Country">{{ item.Country || "" }}</div>
      <div :title="item.BYear">{{ item.BYear }}</div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
// import { countryObject } from "@/constant.js";

const headers = [
  { name: "刪除", key: "", sortDesc: null },
  { name: "身分證字號", key: "", sortDesc: null },
  { name: "姓名", key: "", sortDesc: null },
  { name: "籍屬", key: "", sortDesc: null },
  { name: "學年度", key: "", sortDesc: null },
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1908;//原先是-1905
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "Apply27",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      orderBy2: [],
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchID: null,
      searchName: null,
      compontCalderKey: 0,
      showModal: false, 
    };
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },
  methods: {
    async clearSearch() {
      this.searchSchool = null;
      this.searchYear = new Date().getFullYear() - 1911 - 1;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.orderBy2 = [];

      this.getData();
    },
    async getData() {
      // odata3 qs top&skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.orderBy;
      const obj = orderBy.length ? { top, skip, orderBy } : { top, skip };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");

      let url = "";
      url = `admission3yrs/Get${qs}&ID=${this.searchID}&Name=${this.searchName}`;
      try {
        let { Items, Count } = await window.axios.get(url);
        console.log(Items);
        this.items = JSON.parse(JSON.stringify(Items));
        this.compontCalderKey += 1;
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    showDeleteModal() {
      this.showModal = true;
    },
    // 刪除操作
    deleteItem(id) {
      console.log('項目已刪除');
      console.log(id);
      let url = `admission3yrs/Delete`;  // 設定 API 刪除的 URL
      
      axios.delete(url, {
        params: { selectedID: id }  // 傳遞刪除的 ID 作為參數
      })
      
      .then(response => {
        // 處理刪除成功的邏輯
        this.getData();  
        if (response.status === 200) {
          console.log('刪除成功');
        } else {
          console.log('刪除失敗');
          // 處理刪除失敗的情況
        }
      })
      .catch(error => {
        console.error('刪除操作發生錯誤:', error);
        // 顯示錯誤訊息給用戶
      });
      this.showModal = false;  // 關閉模態視窗
    },

    async fileChange(e) {
      let form = new FormData();
      form.append("input",  e.target.files[0])
      e.target.value = '';
      try {
        const map = await window.axios.post(
          "admission3yrs/Importassignnumber",
          form
        );
        this.$bvToast.toast(`新增成功`, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast("新增失敗" + e, {
          title: "上傳檔案",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.compontCalderKey = 0;
    this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchYear(v) { store.searchYear = v; },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  margin-top: -50px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header {
  // width: 2230px;
  display: grid;
  grid-template-columns: 160px 250px 300px 250px 250px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 40px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header-semester2 {
  grid-template-columns: 60px 120px 150px 140px 160px;
}
.content {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  //   height: 142px;
  margin: 0 auto;
  width: 80%;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
